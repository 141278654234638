import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Clients      from '../views/Clients'
import Calendar     from '../views/Calendar'
import Settings     from '../views/Settings'
import Stades       from '../views/Stades'
import Reservations from '../views/Reservations'
import Analytics    from '../views/Analytics';
import Feedbacks    from '../views/Feedbacks'
import Register    from '../views/Register'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/clients',
    name: 'Clients',
    component: Clients
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar
  },
  {
    path: '/reservations',
    name: 'Reservations',
    component: Reservations
  },
  {
    path: '/stades',
    name: 'Stades',
    component: Stades
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: Analytics
  },
  {
    path: '/feedbacks',
    name: 'feedback',
    component: Feedbacks
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
