<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <a class="navbar-brand" href="#">Stades.ma</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
                <a class="nav-link" href="/"><i class='bx bx-category'></i> Accueil <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/clients"><i class='bx bx-user'></i> Clients</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/calendar"><i class='bx bxs-calendar'></i> Calendrier</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/reservations"><i class='bx bxs-spreadsheet'></i> Réservations</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/stades"><i class='bx bxs-collection'></i> Stades</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/analytics"><i class='bx bx-bar-chart-alt-2' ></i> Analytiques</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href=""> <i class='bx bx-message-alt-detail'></i> Commentaires</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/settings"><i class='bx bxs-wrench' ></i> Réglages</a>
            </li>

            <li class="nav-item" style="cursor:pointer" @click="logout">
                <span class="nav-link">
                    <i class='bx bx-log-out'></i> Se déconnecter</span>
            </li>

            </ul>
        </div>
    </nav>
</template>

<script>
export default {
    methods : {
        logout(){
          localStorage.removeItem('access_token')
          window.location = "/login"
        }
      }
}
</script>