<script>
import SideBar from './views/components/SideBar'
import Navbar  from './views/components/Navbar'
export default {
  components : {
    SideBar,
    Navbar
  },
  data:() => ({
        isAuth : false,
        active: 'primary'
      }),
  created(){
    if(!localStorage.getItem('access_token')){
      this.$router.push({ path: '/login' })
    }
    if(localStorage.getItem('access_token')){
       this.isAuth = true;
    }
  }
}
</script> 
<template>
  <div id="app">
    <div class="mobile-nav">
      <Navbar v-if="isAuth"/>
    </div>
    <SideBar v-if="isAuth" />
    <div :class="isAuth ? 'custom-container' : ''">
        <router-view/>
    </div>
  </div>
</template>

<style>
  .mobile-nav{
    display: none;
  }

  .vs-sidebar-content{
    width: calc(100% - 50px) !important;
    max-width: 210px !important;
  }
  .custom-container{
    width: calc(100% - 254px);
    margin-left: 230px;
    margin-top: 4rem
  }
  @media (max-width: 990px) {
    .mobile-nav{
      display: block;
    }
    .custom-container{
      width:100%;
      padding:1rem;
      margin: 3rem 0rem;
    }

    .vs-sidebar-content{
      display: none !important;
    }  
  }

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
