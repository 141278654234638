  <script>
    import moment from 'moment'
    import {axiosGet,axiosDelete} from '../helper'
    export default {
      created(){
        this.getFeedbacks()
      },
      data:() => ({
        search : '',
        active : false,
        editModal : false,
        viewModal : false,
        deleteModal : false,
        addModal : false,
        row :null,
        feedbacks: [
        ],
        per_page : null,
        current_page : null,
        count : null
      }),
      methods : {
        async getFeedbacks(per_page = 5, page = 1){
          let response = await axiosGet(`${process.env.VUE_APP_API_URL}/api/feedbacks?per_page=${per_page}&page=${page}`)
          this.feedbacks = [...this.feedbacks,...response.data.data];
          for(let i = 0 ; i < this.feedbacks.length ; i++){
            this.feedbacks[i].created_at = moment(this.feedbacks[i].created_at).fromNow()
          }
          this.per_page = response.data.per_page
          this.current_page = response.data.current_page
          this.count = response.data.count
        },
        viewRow(row){
          this.row = row
          this.viewModal = true
        },
        deleteRow(row){
          this.row = row;
          this.deleteModal = true
        },
        async submitDelete(index,row){
          let response = await axiosDelete(`${process.env.VUE_APP_API_URL}/api/feedbacks/${row.id}`)
          this.feedbacks.splice(index, 1);
          this.openNotification('bottom-center', 'danger',3000,'Commentaires supprimés avec succès','')
          this.deleteModal = false;
        },
        openNotification(position = null, color,duration,title,text) {
          const noti = this.$vs.notification({
            duration,
            progress: 'auto',
            color,
            position,
            title,
            text : ' '
          })
        },
        loadMore(){
          this.getFeedbacks(this.per_page,parseInt(this.current_page) + 1)
        }
      } 
    }
</script>

<template>
  <div class="card">
    <div class="center">
      <div class="row" style="padding:1rem">
        <div class="col-sm-8">
          <div class="center content-inputs">
            <!-- <vs-input block v-model="search" placeholder="Search..." /> -->
          </div>
        </div>
      </div>
      <div class="table-responsive" style="height: 500px;overflow-y: scroll;">
        <table class="table" >
          <thead>
                <tr>
                  <th scope="col">Nom</th>
                  <th scope="col">Téléphone</th>
                  <th scope="col">Évaluation</th>
                  <th scope="col">Message</th>
                  <th scope="col">Créé à</th>
                  <th scope="col">Actions</th>

                </tr>
              </thead>
          <tbody>
                <tr v-for="(row,i) in feedbacks" :key="i">
                  <th>{{ row.name }}</th>
                  <td>{{ row.phone }}</td>
                  <td>{{ row.rating }}</td>
                  <td>{{ row.message }}</td>
                  <td>{{ row.created_at }}</td>
                  <td> 
                    <vs-tooltip  style="display:inline">
                          <vs-button
                          style="display:inline"
                          icon
                          danger
                          @click="deleteRow(i,row)"
                        >
                          <i class='bx bxs-trash' ></i>
                        </vs-button>
                        <template #tooltip>
                            Effacer
                          </template>
                        </vs-tooltip>
                  </td>

                </tr>
          </tbody>
        </table>
        <template v-if="(per_page * current_page) <= count ? true : false">
           <vs-button
              style="margin:auto"
              shadow
              @click="loadMore"
            >
              Charger plus
            </vs-button>
        </template>

        <!-- Delete modal -->
            <vs-dialog blur v-if="row" v-model="deleteModal" >
              <template #header>
                <h5 style="padding:1rem;">
                  Etes-vous sûr que vous voulez supprimer <b>{{row.id}}</b>
                </h5>
              </template>

              <template #footer>
                <div class="footer-dialog">
                  <vs-button danger block @click=submitDelete(row)>
                    Oui Supprimer
                  </vs-button>
                </div>
              </template>
            </vs-dialog>
        <!-- delete modal -->


      </div>
    </div>
  </div>
  </template>
