<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr';
import {axiosGet,axiosPost} from '../helper'

import moment from 'moment';
export default {
  props : ['dashboard'],
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data() {
    return {
      hideAddReservation : false,
      viewReservations : false,
      modalAddReservation : false,
      name :'',
      phone : '',
      session : '',
      day  : moment().format('YYYY-MM-DD'),
      from : moment().format('HH:MM'),
      to : moment().add('1','hour').format('HH:MM'),
      stade_id : null,
      stades : [],
      reservations: [],
      calendarOptions: {
       locale: frLocale,
       allDaySlot: false,
       plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'prev,next today',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
      
        slotDuration : '00:60:00',
        slotLabelFormat: [
            {
                hour: '2-digit',
                minute: '2-digit',
                hour12:false
            }
            ],
        selectable: true,
        select: async (start, end) => {
          this.day  = moment(start.startStr).format('YYYY-MM-DD')
          this.from = moment(start.startStr).format('HH:MM')
          this.to = moment(start.endStr).format('HH:MM')
          if(this.from == this.to){
            this.to = moment(start.endStr).add('1','hour').format('HH:MM')
          }
          this.showAddReservation()
        },
        eventClick: async (info) => {
            this.viewReservations = true;
            console.log(info)
            let response = await axiosGet(`${process.env.VUE_APP_API_URL}/api/reservations-details?start=${moment(info.event.start).utc().format()}&session=${moment(info.event.start).format('YYYY-MM-DD HH:mm')}`)
            for(let i = 0 ; i < response.data.length ;i++){
              response.data[i].from = moment(response.data[i].from).format('YYYY-MM-DD HH:mm')
              response.data[i].to = moment(response.data[i].to).format('YYYY-MM-DD HH:mm')
              response.data[i].session = moment(response.data[i].session).format('YYYY-MM-DD HH:mm')
            }
            this.reservations = response.data

        },
        events: `${process.env.VUE_APP_API_URL}/api/reservations-calendar?authorization=${localStorage.getItem('access_token')}`
      }
    }
  },
  created(){
    if(this.dashboard){
      this.hideAddReservation = true
      delete this.calendarOptions.headerToolbar
    }
  },
  methods : {
    async requestAvailableStades(){
      if(this.session){
        let response = await axiosGet(`${process.env.VUE_APP_API_URL}/api/available-stades?day=${this.day}&from=${this.getFromTo()[this.session].from}&to=${this.getFromTo()[this.session].to}&session=${this.session}`)
        this.stades = response.data
      }
     
    },
    async showAddReservation(){
      this.requestAvailableStades()
      this.modalAddReservation = true
    },
    getFromTo(){
      return {
        '07:00' : {from : "07:00", to : "08:00"},
        '08:00' : {from : "08:00", to : "09:00"},
        '09:00' : {from : "09:00", to : "10:00"},
        '10:00' : {from : "10:00", to : "11:00"},
        '11:00' : {from : "11:00", to : "12:00"},
        '12:00' : {from : "12:00", to : "13:00"},
        '13:00' : {from : "13:00", to : "14:00"},
        '14:00' : {from : "14:00", to : "15:00"},
        '15:00' : {from : "15:00", to : "16:00"},
        '16:00' : {from : "16:00", to : "17:00"},
        '17:00' : {from : "17:00", to : "18:00"},
        '18:00' : {from : "18:00", to : "19:00"},
        '19:00' : {from : "19:00", to : "20:00"},
        '20:00' : {from : "20:00", to : "21:00"},
        '21:00' : {from : "21:00", to : "22:00"},
        '22:00' : {from : "22:00", to : "23:00"},
        '23:00' : {from : "23:00", to : "00:00"},
        // '00:00' : {from : "00:00", to : "01:00"},
      }
    },
    async addReservation(){
      try{
          await axiosPost(`${process.env.VUE_APP_API_URL}/api/reservations`,
              {
                name : this.name,
                phone : this.phone,
                day : this.day,
                from : this.getFromTo()[this.session].from,
                to : this.getFromTo()[this.session].to,
                session : this.session,
                stade_id : this.stade_id
              })
          
          this.openNotification('bottom-center', 'success',3000,'Reservation added successfully','')
          this.name = ''
          this.phone = ''
          this.stade_id = null
          this.modalAddReservation = false;
          window.location.reload()
      }catch(error){
          this.openNotification('bottom-center', 'danger',3000,error.response.data.message,'')
      }
      
    },
    openNotification(position = null, color,duration,title,text) {
      this.$vs.notification({
        duration,
        progress: 'auto',
        color,
        position,
        title,
        text : ' '
      })
    }
  },
  watch : {
    async day(){
      this.requestAvailableStades()
    },
    async session(){
      this.requestAvailableStades()
    }
  }

}
</script>
<template>
<div>
        <div class="card" style="padding:2rem">
            <div v-if="!hideAddReservation" style="margin-bottom:1rem">
              <vs-button
              success
              @click="showAddReservation"
              style="float:right;"
              >
              Add Reservations
            </vs-button>
            </div>
            
            <div class="center">
              <FullCalendar ref="calendar" :options="calendarOptions" />
            </div>

          <!-- view modal -->
            <vs-dialog  v-model="viewReservations" >
              <template #header>
                <h4 class="not-margin">
                  Reservations 
                </h4>
              </template>

              <div class="row">
                <vs-table>
                  <template #thead>
                    <vs-tr>
                      <vs-th>
                        Nom
                      </vs-th>
                      <vs-th>
                        Téléphone
                      </vs-th>
                      <vs-th>
                        Session
                      </vs-th>
                      <vs-th>
                        Image
                      </vs-th>
                      <vs-th>
                        Stade
                      </vs-th>
                      <vs-th>
                        Taille
                      </vs-th>
                      <vs-th>
                        Prix
                      </vs-th>
                    </vs-tr>
                  </template>
                  <template #tbody>
                    <vs-tr
                      :key="i"
                      v-for="(tr, i) in reservations"
                      :data="tr"
                    >
                      <vs-td>
                        {{ tr.client.name }}
                      </vs-td>
                      <vs-td>
                      {{ tr.client.phone }}
                      </vs-td>
                      <vs-td style="width:180px">
                      <b>{{  tr.session }}</b>
                      </vs-td>
                      <vs-td>
                      <img :src="tr.stade.photo" width="50" />
                      </vs-td>
                      <vs-td>
                      {{ tr.stade.label }}
                      </vs-td>
                      <vs-td>
                      {{ tr.stade.size }}
                      </vs-td>
                      <vs-td>
                      {{ tr.stade.price }} Dh
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>

            </vs-dialog>
          <!-- view modal -->

          <!-- view modal -->
            <vs-dialog blur v-model="modalAddReservation">
              <template #header>
                <h4 class="not-margin">
                  Add Reservation
                </h4>
              </template>

              <div class="con-form reservation-modal">
                <div class="row">
                  <div class="col-sm-6">
                    <vs-input v-model="name" block label="Name" placeholder="Jhon..."></vs-input>
                  </div>
                  <div class="col-sm-6">
                    <vs-input v-model="phone" block label="Phone" placeholder="06..."></vs-input>
                  </div>
                </div>
                <div class="row" style="margin-top:1rem">
                  <div class="col-sm-6" style="margin-top:1rem">
                    <VueCtkDateTimePicker format="YYYY-MM-DD" formatted="YYYY-MM-DD" :no-label="true" :only-date="true"  v-model="day" />
                  </div>

                  <div class="col-sm-6" style="margin-top:1rem">
                    <div class="form-group">
                      <select v-on:change="session = $event.target.value" class="form-control">
                        <option value="">Session</option>
                        <option value="07:00">07:00</option>
                        <option value="08:00">08:00</option>
                        <option value="09:00">09:00</option>
                        <option value="10:00">10:00</option>
                        <option value="11:00">11:00</option>
                        <option value="12:00">12:00</option>
                        <option value="13:00">13:00</option>
                        <option value="14:00">14:00</option>
                        <option value="15:00">15:00</option>
                        <option value="16:00">16:00</option>
                        <option value="17:00">17:00</option>
                        <option value="18:00">18:00</option>
                        <option value="19:00">19:00</option>
                        <option value="20:00">20:00</option>
                        <option value="21:00">21:00</option>
                        <option value="22:00">22:00</option>
                        <option value="23:00">23:00</option>
                        <!-- <option value="00:00">00:00</option> -->
                      </select>
                    </div>
                    <!-- <VueCtkDateTimePicker format="HH:mm" formatted="HH:mm"  :no-label="true" :only-time="true"  v-model="from" /> -->
                    
                  </div>

                  <div class="col-sm-12" style="margin-top:1rem">
                    <h5>stades disponible</h5>
                      <div class="row" style="height:400px;overflow-y: scroll;">
                        <div v-for="(stade,i) in stades" :key="i" @click="stade_id = stade.id"  style="position:relative;margin-bottom:1rem"  class="col-sm-4">
                          <i v-if="stade_id == stade.id" style="position: absolute;z-index: 99;color: green;font-weight: bold;background: white;font-size: 30px;left: 7%;" class='bx bxs-checkbox-checked'></i>
                          <vs-card>
                            <template #title>
                              <h3>{{stade.label}}</h3>
                            </template>
                            <template #img>
                              <img :src="stade.photo" alt="">
                            </template>
                            <template #text>
                              <ul style="list-style:none;margin: 0;text-align: left; padding: 0;">
                                <li>
                                  label : {{stade.label}}
                                </li>
                                <li>
                                  size : {{stade.size}}
                                </li>
                                <li>
                                  price : {{stade.price}} dh/h
                                </li>
                              </ul>
                            </template>
                          </vs-card>
                        </div>
                      </div>
                  </div>

                </div>
              </div>

              <template #footer>
                <div class="footer-dialog">
                  <vs-button :disabled="stade_id && name != '' && phone != '' && day && from && to  &&  session != '' ? false : true" success block @click="addReservation">
                    Add
                  </vs-button>
                </div>
              </template>
            </vs-dialog>
          <!-- view modal -->

        </div>
</div>
</template>