<script>
    import {axiosGet,axiosPatch} from '../helper'
    export default {
      data:() => ({
            id : null,
            name : "",
            phone : "",
            website : "",
            address : "",
            email : "",
      }),
      mounted(){
        this.getBusiness()
      },
      methods : {
        async getBusiness(){
            let response = await axiosGet(`${process.env.VUE_APP_API_URL}/api/business`)
                this.id   = response.data.id
                this.name = response.data.name
                this.phone = response.data.phone
                this.website = response.data.website
                this.address = response.data.address
                this.email = response.data.email
        },
        async editBusiness(){
          await axiosPatch(`${process.env.VUE_APP_API_URL}/api/businesses/${this.id}`,
            {
              name : this.name,
              phone : this.phone,
              website : this.website,
              address : this.address,
              email  : this.email,
              password : this.password
            })

          this.openNotification('bottom-center', 'success',3000,'Entreprise mise à jour avec succès','')
          this.editModal = false;
        },
        openNotification(position = null, color,duration,title,text) {
          this.$vs.notification({
            duration,
            progress: 'auto',
            color,
            position,
            title,
            text : ' '
          })
        }
      }
    }
</script>

<template>
<div class="">
    <div class="card" style="padding:1rem;width:600px;margin:2rem auto;">
        <div class="center">
                <div class="center content-inputs">
                <h3 class="mb-4">Information générale</h3>

            <div class="row">
                <div class="col-sm-12 mb-4">
                    <vs-input primary v-model="name" block placeholder="Nom de l'entreprise" />
                </div>
                <div class="col-sm-12 mb-4">
                    <vs-input primary v-model="website" block placeholder="www.website.com" />
                </div>

                 <div class="col-sm-12 mb-4">
                    <vs-input primary v-model="phone" block placeholder="0524..." />
                </div>
                <div class="col-sm-12 mb-4">
                    <vs-input primary  v-model="address" block placeholder="Marrakesh..." />
                </div>
                
                 <div class="col-sm-12 mb-4">
                    <vs-input primary  v-model="email" block placeholder="contact@email.com" />

                </div>

            </div>
          
            <div class="p-2 m-1">
            <vs-button block  @click="editBusiness">
                Éditer
            </vs-button>
            </div>
            
            </div>
        </div>
    </div>
<!-- 
    <div class="card" style="padding:1rem;width:600px;margin:auto">
        <div class="center">
                <div class="center content-inputs">
            <h5>Admin info</h5>

            <div class="row">
                <div class="col-sm-6">
                    <vs-input
                        primary
                        v-model="email"
                        block
                        placeholder="Email" />
                </div>
                <div class="col-sm-6">
                    <vs-input
                        primary
                        v-model="password"
                        block
                        placeholder="Mot de passe" />
                </div>
            </div>
            <div class="p-2 m-1">
            <vs-button block  @click="editBusiness">
                Éditer
            </vs-button>
            </div>
            </div>
        </div>
    </div> -->
</div>

  </template>

        