<script>
import LineChart from './components/LineChart'
import {axiosGet,axiosPost,axiosPatch,axiosDelete} from '../helper'

export default {
    components : {LineChart},
    async created(){
        //clients
        let clients = await this.getCharts('clients')

        this.clients.labels = ["11-06","11-07","11-08","11-09","11-10","11-11","11-12","11-13","11-14","11-15","11-16","11-17","11-18","11-19","11-20","11-21","11-22","11-23","11-24","11-25","11-26","11-27","11-28","11-29","11-30","12-01","12-02","12-03","12-04","12-05","12-06"]
        this.clients.datasets[0].data = this.generateRandomData(this.clients.labels.length)
        this.clients.datasets[0].label = 'Clients' 
        this.clients.datasets[0].backgroundColor = '#007bff82' 
        this.clients.datasets[0].borderColor = '#007bff' 

        //reservations
        let reservations = await this.getCharts('reservations')
        this.reservations.labels = ["11-06","11-07","11-08","11-09","11-10","11-11","11-12","11-13","11-14","11-15","11-16","11-17","11-18","11-19","11-20","11-21","11-22","11-23","11-24","11-25","11-26","11-27","11-28","11-29","11-30","12-01","12-02","12-03","12-04","12-05","12-06"]
        this.reservations.datasets[0].data = this.generateRandomData(this.reservations.labels.length)
        this.reservations.datasets[0].label = 'Reservations' 
        this.reservations.datasets[0].backgroundColor = 'rgb(23 201 100 / 40%)' 
        this.reservations.datasets[0].borderColor = 'rgb(23, 201, 100)' 

        //reservations
        let revenue = await this.getCharts('revenue')
        this.revenue.labels = ["11-06","11-07","11-08","11-09","11-10","11-11","11-12","11-13","11-14","11-15","11-16","11-17","11-18","11-19","11-20","11-21","11-22","11-23","11-24","11-25","11-26","11-27","11-28","11-29","11-30","12-01","12-02","12-03","12-04","12-05","12-06"]
        this.revenue.datasets[0].data = this.generateRandomData(this.revenue.labels.length)
        this.revenue.datasets[0].label = 'Revenue' 
        this.revenue.datasets[0].backgroundColor = 'rgb(255 130 0 / 51%)' 
        this.revenue.datasets[0].borderColor = 'rgb(255, 130, 0)' 

        //reservations
        let views = await this.getCharts('revenue')
        this.views.labels = ["11-06","11-07","11-08","11-09","11-10","11-11","11-12","11-13","11-14","11-15","11-16","11-17","11-18","11-19","11-20","11-21","11-22","11-23","11-24","11-25","11-26","11-27","11-28","11-29","11-30","12-01","12-02","12-03","12-04","12-05","12-06"]
        this.views.datasets[0].data = this.generateRandomData(this.views.labels.length)
        this.views.datasets[0].label = 'Views' 
        this.views.datasets[0].backgroundColor = 'rgb(242 19 93 / 52%)' 
        this.views.datasets[0].borderColor = 'rgb(242, 19, 93)' 

        window.line1.update();
        window.line2.update();
        window.line3.update();
        window.line4.update();
    },
    data:() => ({
        active: 'dashboard',
        clients : {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [{
                    label: 'My First dataset',
                    backgroundColor: '#5b3cc429',
                    borderColor: '#5b3cc4',
                    data: []
                }]
        },
        reservations : {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [{
                    label: 'My First dataset',
                    backgroundColor: 'rgb(23, 201, 100)',
                    borderColor: 'rgb(23, 201, 100)',
                    data: []
                }]
        },
        revenue : {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [{
                    label: 'My First dataset',
                    backgroundColor: 'rgb(23, 201, 100)',
                    borderColor: 'rgb(23, 201, 100)',
                    data: []
                }]
        },
        views : {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [{
                    label: 'Views',
                    backgroundColor: 'rgb(242, 19, 93)',
                    borderColor: 'rgb(242, 19, 93)',
                    data: []
                }]
        },
      }),

      // {
      //   "data":[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      //   "label":["11-06","11-07","11-08","11-09","11-10","11-11","11-12","11-13","11-14","11-15","11-16","11-17","11-18","11-19","11-20","11-21","11-22","11-23","11-24","11-25","11-26","11-27","11-28","11-29","11-30","12-01","12-02","12-03","12-04","12-05","12-06"],
      //   "total":0
      // }

      methods : {
        async getCharts(type){
          let response = await axiosGet(`${process.env.VUE_APP_API_URL}/api/businesses/analytics?start=2020-11-06&end=2020-12-06&type=${type}`)
          return response.data
        },
        generateRandomData(length){
          let array = [];
          for(let i = 0 ; i < length ; i++){
            array.push(Math.floor(Math.random() * 349))
          }
          return array
        }
      }
}
</script>
<template>
    <div>
      <div class="row">
        <div class="col-sm-12">
           <div class="row" >
              <div class="col-sm-6" style="margin-bottom:1rem">
                <LineChart chartId="line1" :data="clients" />
              </div>
              <div class="col-sm-6" style="margin-bottom:1rem">
                <LineChart chartId="line2" :data="reservations"/>
              </div>
              <div class="col-sm-6" style="margin-bottom:1rem">
                <LineChart chartId="line3" :data="revenue"/>
              </div>
              <div class="col-sm-6" style="margin-bottom:1rem">
                <LineChart chartId="line4" :data="views"/>
              </div>
           </div>
        </div>

          
      </div>
  </div>
</template>