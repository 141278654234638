<template>
  <vs-dialog blur v-model="state">
              <template #header>
                <h4 class="not-margin">
                  Ajouter une réservation
                </h4>
              </template>

              <div class="con-form reservation-modal">
                
                <div class="row">
                  <div class="col-sm-6">
                    <vs-input v-model="name" block label="Nom" placeholder="Jhon..."></vs-input>
                  </div>
                  <div class="col-sm-6">
                    <vs-input v-model="phone" block label="Téléphone" placeholder="06..."></vs-input>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-sm-6 mt-4">
                          <vs-select placeholder="Téléphone" label="Type De Reservation" v-model="type">
                                <vs-option label="Téléphone" value="phone">
                                  Téléphone
                                </vs-option>
                                <vs-option label="Email" value="email">
                                  Email
                                </vs-option>
                                <vs-option label="Surplace" value="onsite">
                                  Surplace
                                </vs-option>
                            </vs-select>
                  </div>
                  <div class="col-sm-6 mt-4">

                     <vs-select placeholder="En attente" label="Status" v-model="status">
                          <vs-option label="En attente" value="on_hold">
                              En attente
                          </vs-option>
                          <vs-option label="Confirmé" value="confirmed">
                            Confirmé
                          </vs-option>
                          <vs-option label="Annulé" value="canceled">
                            Annulé
                          </vs-option>
                      </vs-select>
                   
                  </div>

                </div>

                

                <div class="row" style="margin-top:1rem">
                  <div class="col-sm-6" style="margin-top:1rem">
                    <VueCtkDateTimePicker format="YYYY-MM-DD" formatted="YYYY-MM-DD" :no-label="true" :only-date="true"  v-model="day" />
                  </div>

                  <div class="col-sm-6" style="margin-top:1rem">
                    <div class="form-group">
                      <select v-on:change="session = $event.target.value" class="form-control">
                        <option value="-1">Séance</option>
                        <option :selected="session == '07:00'" value="07:00">07:00</option>
                        <option :selected="session == '08:00'" value="08:00">08:00</option>
                        <option :selected="session == '09:00'" value="09:00">09:00</option>
                        <option :selected="session == '10:00'" value="10:00">10:00</option>
                        <option :selected="session == '11:00'" value="11:00">11:00</option>
                        <option :selected="session == '12:00'" value="12:00">12:00</option>
                        <option :selected="session == '13:00'" value="13:00">13:00</option>
                        <option :selected="session == '14:00'" value="14:00">14:00</option>
                        <option :selected="session == '15:00'" value="15:00">15:00</option>
                        <option :selected="session == '16:00'" value="16:00">16:00</option>
                        <option :selected="session == '17:00'" value="17:00">17:00</option>
                        <option :selected="session == '18:00'" value="18:00">18:00</option>
                        <option :selected="session == '19:00'" value="19:00">19:00</option>
                        <option :selected="session == '20:00'" value="20:00">20:00</option>
                        <option :selected="session == '21:00'" value="21:00">21:00</option>
                        <option :selected="session == '22:00'" value="22:00">22:00</option>
                        <option :selected="session == '23:00'" value="23:00">23:00</option>
                        <option :selected="session == '00:00'" value="00:00">00:00</option>
                      </select>
                    </div>
                    <!-- <VueCtkDateTimePicker format="HH:mm" formatted="HH:mm"  :no-label="true" :only-time="true"  v-model="from" /> -->
                    
                  </div>

                  <div class="col-sm-12" style="margin-top:1rem">
                   <h5>stades disponible</h5>
                      <div class="row" style="height:250px;overflow-y: scroll;">
                        <div v-for="(stade,i) in stades" :key="i" @click="selectStade(stade)"  style="position:relative;margin-bottom:1rem"  class="col-sm-4">
                          <i v-if="stade_id == stade.id" style="position: absolute;z-index: 99;color: green;font-weight: bold;background: white;font-size: 30px;left: 7%;" class='bx bxs-checkbox-checked'></i>
                          <vs-card>
                            <template #title>
                              <h3>{{stade.label}}</h3>
                            </template>
                            <template #img>
                              <img :src="stade.photo" alt="">
                            </template>
                            <template #text>
                              <ul style="list-style:none;margin: 0;text-align: left; padding: 0;">
                                <li>
                                  Taille : {{stade.size}}
                                </li>
                                <li>
                                  Prix : {{stade.price}} dh/h
                                </li>
                              </ul>
                            </template>
                          </vs-card>
                        </div>
                      </div>
                  </div>

                </div>

                <div class="row mt-4">
                  <div class="col-sm-6 mt-4">
                    <vs-input v-model="advance" type="number" block label="Avance" min="0" :max="stade_price" placeholder="50"></vs-input><small>Le reste ({{stade_price - advance}} Dh)</small>
                  </div>
                  <div class="col-sm-6 mt-4">
                    <vs-input v-model="additional_information" block label="Informations Complémentaires" placeholder="..."></vs-input>
                  </div>
                </div>

              </div>

              <template #footer>
                <div class="footer-dialog">
                  
                  <vs-button v-if="data" :disabled="stade_id && name != '' && phone != '' && day && session != '-1' ? false : true" success block @click="editReservation">
                    Editer
                  </vs-button>
                  <vs-button v-else :disabled="stade_id && name != '' && phone != '' && day && session != '-1' ? false : true" success block @click="addReservation">
                    Ajouter
                  </vs-button>

                </div>
              </template>
            </vs-dialog>
</template>

<script>
import moment from 'moment'
    import {axiosGet,axiosPost,axiosPatch,axiosDelete} from '../../helper'

export default {
    props : ['data'],
    data(){
        return {
            name :'',
            phone : '',
            session : '',
            day  : moment().format('YYYY-MM-DD'),
            stade_id : null,
            stades : [],
            filter : 'all',
            type : '',
            status : '',
            advance : 0,
            stade_price: 0,
            additional_information : '',
            state : true,
            id :null
        }
    },
    mounted(){
        this.requestAvailableStades()
        if(this.data){
            this.id = this.data.id
            this.name = this.data.name
            this.phone = this.data.phone
            this.type = this.data.type
            this.status = this.data.status
            this.day = this.data.day
            this.session = this.data.session
            this.stade_id = this.data.stade_id
            this.stade_price = this.data.stade_price
            this.advance = this.data.advance
            this.additional_information = this.data.additional_information
        }
    },
    methods : {
        selectStade(stade){
          if(this.stade_id == stade.id){
            this.stade_id = null
            this.stade_price = 0
          }else{
            this.stade_id = stade.id
            this.stade_price = stade.price
          }

        },
        async addReservation(){
            await axiosPost(`${process.env.VUE_APP_API_URL}/api/reservations`,
                {
                    name : this.name,
                    phone : this.phone,
                    day : this.day,
                    session : this.session,
                    stade_id : this.stade_id,
                    advance : this.advance,
                    status : this.status,
                    type : this.type,
                    additional_information: this.additional_information
                })
            this.openNotification('bottom-center', 'success',3000,'Réservation ajoutée avec succès','')
            this.modalAddReservation = false;
        },
        async editReservation(){
             await axiosPatch(`${process.env.VUE_APP_API_URL}/api/reservations/${this.id}`,
                {
                    name : this.name,
                    phone : this.phone,
                    day : this.day,
                    session : this.session,
                    stade_id : this.stade_id,
                    advance : this.advance,
                    status : this.status,
                    type : this.type,
                    additional_information: this.additional_information
                })
            this.openNotification('bottom-center', 'success',3000,'Réservation Edité avec succès','')
        },
        async requestAvailableStades(){
            if(this.session){
              let response = await axiosGet(`${process.env.VUE_APP_API_URL}/api/available-stades?day=${this.day}&session=${this.session}`)
              this.stades = response.data
            }
          },
        
    },
    watch : {
        session(){
            this.requestAvailableStades()
        },
        day(){
            this.requestAvailableStades()
        },
        state(){
            this.$emit('addReservationModalState',this.state)
        }
    }
}
</script>

<style>

</style>