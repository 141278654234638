
<template>
  <div class="card" style="padding:1rem">
    <h2 style="text-align:left">Reservations</h2>
    <div class="center">
      <div class="row" style="padding:1rem">
        <div class="col-sm-8">
           <div class="center content-inputs">
            <vs-input block v-model="search" border placeholder="Search...">
              <template #icon>
                  <i class='bx bx-search'></i>
                </template>
            </vs-input>
          </div>
        </div>
        <div class="col-sm-4" style="text-align:right">
            <vs-button style="display:inline" class="mb-4" success @click="modalAddReservation = true">
              Ajouter une réservation
            </vs-button>
        </div>
        <div class="col-sm-3">
          
        </div>
      </div>

      <div class="table-responsive mt-4" style="height: 500px;overflow-y: scroll;">
        <table class="table" >
          <thead>
                <tr>
                  <th scope="col">Nom</th>
                  <th scope="col">Téléphone</th>
                  <th scope="col">label</th>
                  <th scope="col">prix</th>
                  <th scope="col">taille</th>
                  <th scope="col">Session</th>
                  <th scope="col">Actions</th>

                </tr>
              </thead>
          <tbody>
                <tr v-for="(row,i) in reservations" :key="i">
                  <th>{{ row.client.name }}</th>
                  <td>{{ row.client.phone }}</td>
                  <td>{{ row.stade.label }}</td>
                  <td>{{ row.stade.price }}</td>
                  <td>{{ row.stade.size }}</td>
                  <td>{{ row.session }}</td>
                  <td> 
                    <vs-tooltip style="display:inline">
                          <vs-button style="display:inline" icon danger @click="deleteRow(i,row)">
                          <i class='bx bxs-trash' ></i>
                        </vs-button>
                        <template #tooltip>
                            Effacer
                          </template>
                        </vs-tooltip>
                        <vs-tooltip  style="display:inline">
                          <vs-button style="display:inline" icon success @click="editReservation(row)">
                          <i class='bx bxs-edit'></i>
                        </vs-button>
                          <template #tooltip>
                            Éditer
                          </template>
                        </vs-tooltip>
                  </td>

                </tr>
          </tbody>
        </table>
        
        <div v-if="!reservations.length">Aucune réservation trouvée</div>

        <template v-if="(per_page * current_page) < count ? true : false">
           <vs-button style="margin:auto" shadow @click="loadMore">
              Charger plus
            </vs-button>
        </template>
      </div>
    </div>


      <!-- add modal -->
            <add-reservation-modal v-if="modalAddReservation" @addReservationModalState="getAddReservationState" />
      <!-- add modal -->

            <!-- add modal -->
            <add-reservation-modal v-if="modalEditReservation" :data="row" @addReservationModalState="getAddReservationState" />
      <!-- add modal -->


      <!-- Delete modal -->
        <vs-dialog blur v-if="row" v-model="deleteModal" >
          <template #header>
            <h5 style="padding:1rem;">
              Etes-vous sûr que vous voulez supprimer <b>{{row.id}}</b>
            </h5>
          </template>

          <template #footer>
            <div class="footer-dialog">
              <vs-button danger block @click=submitDelete(row)>
                Oui Supprimer
              </vs-button>
            </div>
          </template>
        </vs-dialog>
      <!-- delete modal -->

    
  </div>
</template>

    <script>
    import AddReservationModal from './components/AddReservationModal.vue'
    import moment from 'moment'
    import {axiosGet,axiosPost,axiosPatch,axiosDelete} from '../helper'
    export default {
      components : {AddReservationModal},
      created(){
        this.getReservations()
      },
      data:() => ({
        search : '',
        active : false,
        editModal : false,
        viewModal : false,
        deleteModal : false,
        addModal : false,
        row :null,
        modalAddReservation : false,
        modalEditReservation : false,
        reservations: [],
        per_page : null,
        current_page : null,
        count : null,
        name :'',
        phone : '',
        session : '',
        day  : moment().format('YYYY-MM-DD'),
        stade_id : null,
        stades : [],
        filter : 'all',
        type : '',
        status : '',
        advance : 0,
        stade_price: 0,
        additional_information : ''
      }),
      methods : {
        async requestAvailableStades(){
            if(this.session){
              let response = await axiosGet(`${process.env.VUE_APP_API_URL}/api/available-stades?day=${this.day}&session=${this.session}`)
              this.stades = response.data
            }
          },
        async getReservations(per_page = 5,page = 1){
          let response = await axiosGet(`${process.env.VUE_APP_API_URL}/api/reservations?per_page=${per_page}&page=${page}&filter=${this.filter}`)
          this.reservations = [...this.reservations,...response.data.data];
          for(let i = 0 ; i < this.reservations.length ; i++){
            this.reservations[i].created_at = moment(this.reservations[i].created_at).fromNow()
            this.reservations[i].session = moment(this.reservations[i].session).format('YYYY-MM-DD HH:mm')
          }
          this.per_page = response.data.per_page
          this.current_page = response.data.current_page
          this.count = response.data.count
        },
        async editReservation(row){
          console.log(row)
          this.modalEditReservation = true
          this.row = {
            id : row.id,
            stades : row.stade,
            name : row.client.name,
            phone : row.client.phone,
            day : moment(row.session).format('YYYY-MM-DD'),
            session : row.session.split(' ')[1],
            stade_id : row.stade.id,
            stade_price : row.stade.price,
            additional_information : row.additional_information,
            type : row.type,
            status : row.status,
            advance : row.advance,
          }
        },
        deleteRow(index,row){
          this.row = row;
          this.row.index = index;
          this.deleteModal = true
        },
        addRow() {
          this.row = {name :'', phone :''};
          this.addModal = true
        },
        async submitDelete(row){
          await axiosDelete(`${process.env.VUE_APP_API_URL}/api/reservations/${row.id}`)
          this.reservations.splice(this.row.index, 1);
          this.openNotification('bottom-center', 'danger',3000,'Réservation supprimée avec succès','')
          this.deleteModal = false;
        },
        async submitEdit(){
          await axiosPatch(`${process.env.VUE_APP_API_URL}/api/reservations`,
                {
                    name : this.name,
                    phone : this.phone,
                    day : this.day,
                    session : this.session,
                    stade_id : this.stade_id,
                    advance : this.advance,
                    status : this.status,
                    type : this.type,
                    additional_information: this.additional_information
                })
            this.openNotification('bottom-center', 'success',3000,'Réservation Edité avec succès','')
            this.modalEditReservation = false;
            this.getReservations()
        },
        async submitAdd(row){
          let response = await axiosPost(`${process.env.VUE_APP_API_URL}/api/reservations`,
            {
              name : this.row.name,
              phone : this.row.phone
            })
          response.data.created_at = response.data.createdAt
          this.reservations.push(response.data)
          this.openNotification('bottom-center', 'success',3000,'Réservation ajoutée avec succès','')
          this.addModal = false;
        },
        async addReservation(){
            await axiosPost(`${process.env.VUE_APP_API_URL}/api/reservations`,
                {
                    name : this.name,
                    phone : this.phone,
                    day : this.day,
                    session : this.session,
                    stade_id : this.stade_id,
                    advance : this.advance,
                    status : this.status,
                    type : this.type,
                    additional_information: this.additional_information
                })
            this.openNotification('bottom-center', 'success',3000,'Réservation ajoutée avec succès','')
            this.modalAddReservation = false;
            this.getReservations()
        },
        openNotification(position = null, color,duration,title,text) {
          const noti = this.$vs.notification({
            duration,
            progress: 'auto',
            color,
            position,
            title,
            text : ' '
          })
        },
        loadMore(){
          this.getReservations(this.per_page,parseInt(this.current_page) + 1)
        },
        selectStade(stade){
          this.stade_id = stade.id
          this.stade_price = stade.price
        },
        //Add Reservation modal 
        getAddReservationState(state){
          console.log('stattee',state)
          this.modalAddReservation = state
          this.modalEditReservation = state
        }
      },
       watch : {
          async day(){
            this.requestAvailableStades()
          },
          async session(){
            this.requestAvailableStades()
          },
          search(){
              clearTimeout(window.popTimeout);
                  window.popTimeout = setTimeout( async () => {
                    if(this.search == ""){
                      this.reservations = []
                      this.getReservations()
                    }
                  if(this.search.length < 3)return;

                let response = await axiosPost(`${process.env.VUE_APP_API_URL}/api/reservations/search`,
                  {
                    keyword : this.search,
                  })
                  for(let i = 0 ; i < response.data.length ; i++){
                      response.data[i].created_at = moment(response.data[i].createdAt).fromNow()  
                      response.data[i].updated_at = moment(response.data[i].updatedAt).fromNow() 
                  }
  
                  this.reservations = response.data
            },500)
          },
          filter(){
            this.reservations = []
            this.getReservations()
          }
        } 
    }
</script>
