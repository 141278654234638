<template>
  <div class="card" style="padding:1rem">
      <h2 style="text-align:left">Clients</h2>
      <div class="center">
        <div class="row" style="padding:1rem">
          <div class="col-sm-8">
            <div class="center content-inputs">
              <vs-input block v-model="search" border placeholder="Search...">
                <template #icon>
                    <i class='bx bx-search'></i>
                  </template>
              </vs-input>
            </div>
          </div>
          <div class="col-sm-4" style="text-align:right">
              <vs-button style="display:inline" success @click="addRow"> Ajouter un client</vs-button>
          </div>
          <div class="col-sm-3">
            
          </div>
        </div>
        <div class="table-responsive" style="height: 500px;overflow-y: scroll;">
          <table class="table" >
            <thead>
                  <tr>
                    <th scope="col">#Id</th>
                    <th scope="col">Nom</th>
                    <th scope="col">Téléphone</th>
                    <th scope="col">Nbr de réservations</th>
                    <th scope="col">Créé à</th>
                    <th scope="col">Modifé à</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
            <tbody>
                  <tr v-for="(row,i) in clients" :key="i">
                    <th>{{ row.id }}</th>
                    <th>{{ row.name }}</th>
                    <td>{{ row.phone }}</td>
                    <th><span style="cursor:pointer" @click="openModalReservations(row)">{{ row.reservations ? row.reservations.length : 0 }}</span></th>
                    <td>{{ row.createdAt }}</td>
                    <td>{{ row.updatedAt }}</td>
                    <td> 
                      <vs-tooltip style="display:inline">
                        <vs-button style="display:inline" icon danger @click="deleteRow(i,row)">
                          <i class='bx bxs-trash'></i>
                        </vs-button>
                        <template #tooltip>Effacer</template>
                      </vs-tooltip>
                      <vs-tooltip style="display:inline">
                        <vs-button style="display:inline" icon success @click="editRow(row)">
                          <i class='bx bxs-edit'></i>
                        </vs-button>
                        <template #tooltip>Éditer</template>
                      </vs-tooltip>
                    </td>

                  </tr>
            </tbody>
          </table>
          <div v-if="!clients.length">Aucun client trouvé</div>
          <template v-if="(per_page * current_page) < count ? true : false">
            <vs-button style="margin:auto" shadow @click="loadMore">Charger plus</vs-button>
          </template>
        </div>

        <!-- Add modal -->
            <vs-dialog v-if="row" blur v-model="addModal">
              <template #header>
                <h4 class="not-margin">
                  Ajouter un <b>Client</b>
                </h4>
              </template>
              
              <div class="con-form">
                <div class="row" style="padding:1rem">
                      <vs-input class="mb-4" block v-model="row.name" placeholder="Ahmed"></vs-input>
                      <vs-input class="mb-4" block v-model="row.phone" placeholder="06...."></vs-input>
                </div>
              </div>

              <template #footer>
                <div class="footer-dialog">
                  <vs-button block success @click="submitAdd(row)">Ajouter</vs-button>
                </div>
              </template>
          </vs-dialog>
          <!-- Add modal -->

            <!-- edit modal -->
            <vs-dialog v-if="row" blur v-model="editModal">
              <template #header>
                <h4 class="not-margin">
                  Éditer <b>{{row.name}}</b>
                </h4>
              </template>
              
              <div class="con-form">
                <div class="row" style="padding:1rem">
                  <vs-input class="mb-4" block v-model="row.name" placeholder="Ahmed"></vs-input>
                  <vs-input class="mb-4" block v-model="row.phone" placeholder="06...."></vs-input>
                </div>
              </div>

              <template #footer>
                <div class="footer-dialog">
                  <vs-button block success @click="submitEdit(row)">Éditer</vs-button>
                </div>
              </template>
          </vs-dialog>
          <!-- edit modal -->


          <!-- Delete modal -->
              <vs-dialog blur v-if="row" v-model="deleteModal" >
                <template #header>
                  <h5 style="padding:1rem;">Etes-vous sûr que vous voulez supprimer <b>{{row.name}}</b> </h5>
                </template>

                <template #footer>
                  <div class="footer-dialog">
                    <vs-button danger block @click=submitDelete(row)>Oui Supprimer</vs-button>
                  </div>
                </template>
              </vs-dialog>
          <!-- delete modal -->

          <!-- view modal -->
              <vs-dialog blur v-if="row" v-model="viewModal" >
                <template #header>
                  <h4 class="not-margin">
                    View <b>{{row.label}}</b>
                  </h4>
                </template>
              
                <div class="con-form">
                  <vs-input disabled class="mb-3" block v-model="row.label" placeholder="Label">
                  </vs-input>

                  <vs-input disabled class="mb-3" block v-model="row.size" placeholder="Size">
                  </vs-input>

                  <vs-input disabled class="mb-3" block v-model="row.price" placeholder="Price">
                  </vs-input>
                </div>
              </vs-dialog>
          <!-- view modal -->

          <!-- view modal -->
            <ReservationModal @reservationModalState="getReservationState" v-if="viewReservations" :client_id="row.id" />
          <!-- view modal -->

      </div>
  </div>
</template>

<script>
    import moment from 'moment'
    import ReservationModal from './components/ReservationModal.vue'
    import {
      axiosGet,
      axiosPost,
      axiosPatch,
      axiosDelete
    } from '../helper'

    export default {
      components : {ReservationModal},
      created(){
        this.getClients()
      },
      data:() => ({
        search : '',
        active : false,
        editModal : false,
        viewModal : false,
        viewReservations : false,
        deleteModal : false,
        addModal : false,
        row :null,
        clients: [],
        reservations: [],
        per_page : null,
        current_page : null,
        count : null,
        filter : 'all'
      }),
      methods : {
        async getClients(per_page = 10, page = 1){
            let response = await axiosGet(`${process.env.VUE_APP_API_URL}/api/clients?per_page=${per_page}&page=${page}&filter=${this.filter}`)
            this.clients = [...this.clients,...response.data.data];
            for(let i = 0 ; i < this.clients.length ; i++){
              this.clients[i].createdAt = moment(this.clients[i].createdAt).fromNow()
              this.clients[i].updatedAt = moment(this.clients[i].updatedAt).fromNow()
            }
            this.per_page = response.data.per_page
            this.current_page = response.data.current_page
            this.count = response.data.count
        },
        editRow(row){
          this.row = row
          this.editModal = true
        },
        viewRow(row){
          this.row = row
          this.viewModal = true
        },
        deleteRow(index,row){
          this.row = row;
          this.row.index = index;
          this.deleteModal = true
        },
        addRow() {
          this.row = {name :'', phone :''};
          this.addModal = true
        },
        async submitDelete(row){
          try{
            await axiosDelete(`${process.env.VUE_APP_API_URL}/api/clients/${row.id}`)
            this.clients.splice(row.index, 1);
            this.openNotification('bottom-center', 'danger',3000,'Client supprimé avec succès','')
            this.deleteModal = false;
          }catch(error){
            this.openNotification('bottom-center', 'danger',3000,error.message,'')
          }
          
        },
        async submitEdit(row){
          try{
            await axiosPatch(`${process.env.VUE_APP_API_URL}/api/clients/${row.id}`,
              {
                name : this.row.name,
                phone : this.row.phone
              })

            this.openNotification('bottom-center', 'success',3000,'Client mis à jour avec succès','')
            this.editModal = false;
          }catch(error){
            this.openNotification('bottom-center', 'danger',3000,error.message,'')
          }
        },
        async submitAdd(){
          try{
            let response = await axiosPost(`${process.env.VUE_APP_API_URL}/api/clients`,
              {
                name : this.row.name,
                phone : this.row.phone
              })
              
            response.data.createdAt = moment(response.data.createdAt).fromNow() 
            this.clients.push(response.data)
            this.openNotification('bottom-center', 'success',3000,'Client ajouté avec succès','')
            this.addModal = false;
          }catch(error){
            this.openNotification('bottom-center', 'danger',3000,error.message,'')
          }
          
        },
        openNotification(position = null, color,duration,title,text) {
          const noti = this.$vs.notification({
            duration,
            progress: 'auto',
            color,
            position,
            title,
            text : ' '
          })
        },
        loadMore(){
          this.getClients(this.per_page,parseInt(this.current_page) + 1)
        },

        //reservation modal functions
        async openModalReservations(row){
          this.row = row
          this.viewReservations = true
        },
        getReservationState(state){
          this.viewReservations = state
        }
      },
      watch : {
        search(){
              clearTimeout(window.popTimeout);
                  window.popTimeout = setTimeout( async () => {
                    if(this.search == ""){
                      this.clients = []
                      this.getClients()
                    }
                  if(this.search.length < 3)return;

                let response = await axiosPost(`${process.env.VUE_APP_API_URL}/api/clients/search`,
                  {
                    keyword : this.search,
                  })
                  for(let i = 0 ; i < response.data.length ; i++){
                      response.data[i].created_at = moment(response.data[i].createdAt).fromNow()  
                      response.data[i].updated_at = moment(response.data[i].updatedAt).fromNow() 
                  }
  
                  this.clients = response.data
            },500)
        },
        filter(){
          this.clients = []
          this.getClients()
        }
      }
    }
</script>