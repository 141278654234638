<script>
    import {
      axiosPost
      } from '../helper'
    export default {
      name: 'Register',
      data:() => ({
        email : '',
        password : ''
      }),
      methods : {
        async register(){
          if(this.email == "" || this.password == ""){
            this.openNotification('bottom-center', 'danger',3000,'Please fill all inputs','')
            return;
          }
          let response =await axiosPost(`${process.env.VUE_APP_API_URL}/api/businesses/signin`,{
            email : this.email,
            password : this.password
          })
          if(response.data && response.data.access_token){
            localStorage.setItem('access_token',response.data.access_token)
            window.location = "/"
            // this.$router.push({ path: '/' })
          }else{
            this.openNotification('bottom-center', 'danger',3000,'Wrong email or password','')
          }
        },
        openNotification(position = null, color,duration,title,text) {
          const noti = this.$vs.notification({
            duration,
            progress: 'auto',
            color,
            position,
            title,
            text : ' '
          })
        }
      }
    }
  </script>

<template>
  <div class="home">
    <div class="center content-inputs" style="margin: 12rem auto;width: 500px;padding: 2rem;">
      <div class="card" style="padding:3rem 1rem 1rem">
          <h2>Register</h2>
          <div class="con-form">
          <vs-input v-model="email" block placeholder="Email" style="margin-bottom:1rem;">
            <template #icon>
              @
            </template>
          </vs-input>
          <vs-input type="password" block v-model="password" placeholder="Password">
            <template #icon>
              <i class='bx bxs-lock'></i>
            </template>
          </vs-input>
          <div class="" style="text-align:left;font-size:12px;margin-top:1rem;margin-bottom:1rem">
             <vs-button @click="login" style="margin:auto">
              Se connecter
            </vs-button>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</template>