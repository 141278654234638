<template>
    <div>
        <div class="card">
            <div class="center">
            <canvas :id="chartId"></canvas>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props : ['chartId','data'],
    mounted(){
        var ctx = document.getElementById(this.chartId).getContext('2d');
        window[this.chartId] = new Chart(ctx, {
            // The type of chart we want to create
            type: 'line',

            // The data for our dataset
            data: this.data,
            options: {
                elements: {
                    point:{
                        radius: 0
                    }
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            display: false
                        },
                        gridLines: {
                            display:false
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            display: false
                        },
                        gridLines: {
                            display:false
                        }   
                    }]
                }
            }
        });
    }   
}
</script>