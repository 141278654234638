    <script>
    export default {
      created(){
        if(!localStorage.getItem('access_token')){
            this.$router.push({ path: '/login' })
        }
      },
      data:() => ({
        active: 'dashboard',
      }),
      methods : {
        logout(){
          localStorage.removeItem('access_token')
          window.location = "/login"
        }
      }
    }
  </script>
<template>
    <vs-sidebar style="border-radius:0" v-model="active" open>
        <img class="mb-6" style="visibility:display;width:100%;margin-bottom:2rem" :src="require('@/assets/admin.jpg')" alt="">
        <!-- <a href="/" style="text-decoration: none;"> -->
        <vs-sidebar-item id="dashboard" style="outline:none">
          <template #icon>
              <i class='bx bx-category'></i>
          </template>
          <router-link class="nav-link" to="/">Accueil</router-link>
          <!-- Dashboard -->
        </vs-sidebar-item>
        <!-- </a> -->
       
        <!-- <a href="/clients" style="text-decoration: none;"> -->
        <vs-sidebar-item id="clients" style="outline:none">
            <template #icon>
                <i class='bx bx-user'></i>
            </template>
            <router-link class="nav-link" to="/clients">Clients</router-link>
        </vs-sidebar-item>
        <!-- </a> -->

        <!-- <a href="/calendar" style="text-decoration: none;"> -->
        <vs-sidebar-item id="calendar" style="outline:none">
        <template #icon>
          <i class='bx bxs-calendar'></i>
        </template>
            <router-link class="nav-link" to="/calendar">Calendrier</router-link>
            <!-- Calendar -->
        </vs-sidebar-item>
        <!-- </a> -->

        <!-- <a href="/reservations" style="text-decoration: none;"> -->
            <vs-sidebar-item id="reservations" style="outline:none">
              <template #icon>
                  <i class='bx bxs-spreadsheet'></i>
              </template>
              <router-link class="nav-link" to="/reservations">Réservations</router-link>
              <!-- Reservations -->
            </vs-sidebar-item>
        <!-- </a> -->

        <!-- <a href="/stades" style="text-decoration: none;"> -->
            <vs-sidebar-item id="stades" style="outline:none">
              <template #icon>
                  <i class='bx bxs-collection'></i>
              </template>
              <router-link class="nav-link" to="/stades">Stades</router-link>
              <!-- Stades -->
            </vs-sidebar-item>
        <!-- </a> -->

        <!-- <a href="/stades" style="text-decoration: none;"> -->
            <!-- <vs-sidebar-item id="analytics" style="outline:none">
              <template #icon>
                <i class='bx bx-bar-chart-alt-2' ></i>
              </template>
              <router-link class="nav-link" to="/analytics">Analytiques</router-link>
            </vs-sidebar-item> -->
        <!-- </a> -->

        <!-- <a href="/stades" style="text-decoration: none;"> -->
            <!-- <vs-sidebar-item id="feedbacks" style="outline:none">
              <template #icon>
                <i class='bx bx-message-alt-detail'></i>
              </template>
              <router-link class="nav-link" to="#">Commentaires</router-link>
            </vs-sidebar-item> -->
        <!-- </a> -->

        <!-- <a href="/settings" style="text-decoration: none;"> -->
            <vs-sidebar-item id="settings" style="outline:none">
              <template #icon>
                  <i class='bx bxs-wrench' ></i>
              </template>
              <router-link class="nav-link" to="/settings">Business</router-link>
              <!-- Settings -->
            </vs-sidebar-item>
        <!-- </a> -->
        <vs-sidebar-item id="drink" style="outline:none">
          <template #icon>
            <i class='bx bx-log-out'></i>
          </template>
          <div style="color: #007bff;text-decoration: none;background-color: transparent;padding: 0.5rem 1rem;" @click="logout">Se déconnecter</div>
          
        </vs-sidebar-item>

        <!-- <template #footer>
          <vs-row justify="space-between">
            <vs-avatar>
              <img src="https://khalidskiod.com/img/me.jpg" alt="">
            </vs-avatar>
          </vs-row>
        </template> -->
      </vs-sidebar>
</template>

<style>
  /* .nav-link{
    color:white
  }

  .nav-link .bx{
    color:white !important
  }
  .nav-link:hover{
    color:white;
  } */
</style>