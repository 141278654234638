  <script>
    import moment from 'moment'
    import {axiosGet,axiosPost,axiosPatch,axiosDelete} from '../helper'
    export default {
      created(){
        this.getStades()
      },
      data:() => ({
        search : '',
        active : false,
        editModal : false,
        viewModal : false,
        deleteModal : false,
        addModal : false,
        row :null,
        tmpImg:`${process.env.VUE_APP_API_URL}/default_stadium.png`,
        stades: [],
        per_page : null,
        current_page : null,
        count : null,
        terrain :''
      }),
      methods : {
        async getStades(per_page = 5, page = 1){
            let response = await axiosGet(`${process.env.VUE_APP_API_URL}/api/stades?per_page=${per_page}&page=${page}`)
            this.stades = [...this.stades,...response.data.data];
            for(let i = 0 ; i < this.stades.length ; i++){
              this.stades[i].created_at = moment(this.stades[i].created_at).fromNow()
              this.stades[i].updated_at = moment(this.stades[i].updated_at).fromNow()
            }
            this.per_page = response.data.per_page
            this.current_page = response.data.current_page
            this.count = response.data.count
        },
        editRow(i,row){
          this.row = row
          this.row.index = i
          this.editModal = true
        },
        viewRow(row){
          this.row = row
          this.viewModal = true
        },
        deleteRow(index,row){
          this.row = row;
          this.row.index = index;
          this.deleteModal = true
        },
        addRow() {
          this.row = {label :'', size :'' , price : '' , file : null};
          this.addModal = true
        },
        async submitDelete(row){
            await axiosDelete(`${process.env.VUE_APP_API_URL}/api/stades/${row.id}`)
            this.stades.splice(row.index,1)
            this.openNotification('bottom-center', 'danger',3000,'Stades supprimés avec succès','')
            this.deleteModal = false;
        },
        async submitEdit(row){
            let response = await axiosPatch(`${process.env.VUE_APP_API_URL}/api/stades/${row.id}`,
              {
                label : this.row.label,
                size : this.row.size,
                price : this.row.price
              })

            response.data.created_at = moment(response.data.createdAt).fromNow()  
            response.data.updated_at = moment(response.data.updatedAt).fromNow()  
            
            this.stades[this.row.index] = response.data
            this.openNotification('bottom-center', 'success',3000,'Stade mis à jour avec succès','')
            this.editModal = false;
        },
        async submitAdd(){
            let response = await axiosPost(`${process.env.VUE_APP_API_URL}/api/stades`,
              {
                label : this.row.label,
                size : this.row.size,
                price : this.row.price
              })
            if(response.data){
              response.data.created_at = moment(response.data.createdAt).fromNow()  
              response.data.updated_at = moment(response.data.updatedAt).fromNow()  
              this.stades.unshift(response.data)
            }

            this.openNotification('bottom-center', 'success',3000,'Stade ajouté avec succès','')
            this.addModal = false;
        },
        openNotification(position = null, color,duration,title,text) {
          this.$vs.notification({
            duration,
            progress: 'auto',
            color,
            position,
            title,
            text : ' '
          })
        },
        loadMore(){
          this.getStades(this.per_page,parseInt(this.current_page) + 1)
        }
      },
      watch : {
        search(){
              clearTimeout(window.popTimeout);
                  window.popTimeout = setTimeout( async () => {
                    if(this.search == ""){
                      this.stades = []
                      this.getStades()
                    }
                  if(this.search.length < 3)return;

                let response = await axiosPost(`${process.env.VUE_APP_API_URL}/api/stades/search`,
                  {
                    keyword : this.search,
                  })
                  for(let i = 0 ; i < response.data.length ; i++){
                      response.data[i].created_at = moment(response.data[i].createdAt).fromNow()  
                      response.data[i].updated_at = moment(response.data[i].updatedAt).fromNow() 
                  }
  
                  this.stades = response.data
            },500)
        }
      }
    }
</script>

<template>
  <div class="card" style="padding:1rem">
    <h2 style="text-align:left">Stades</h2>
    <div class="center">
      <div class="row" style="padding:1rem">
        <div class="col-sm-8">
          <div class="center content-inputs">
             <vs-input block v-model="search" border placeholder="Search...">
              <template #icon>
                  <i class='bx bx-search'></i>
                </template>
            </vs-input>
          </div>
        </div>
        <div class="col-sm-4" style="text-align:right">
            <vs-button style="display:inline" success @click="addRow">
              Ajouter un Stade
            </vs-button>
        </div>
      </div>

      <div class="table-responsive" style="height: 500px;overflow-y: scroll;">
        <table class="table" >
          <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Étiquette</th>
                  <th scope="col">Taille</th>
                  <th scope="col">Prix</th>
                  <th scope="col">Photo</th>
                  <th scope="col">Créé à</th>
                  <th scope="col">Modifié à</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
          <tbody>
                <tr v-for="(row,i) in stades" :key="i">
                  <th>{{ row.id }}</th>
                  <th>{{ row.label }}</th>
                  <td>{{ row.size }}</td>
                  <td>{{ row.price }}</td>
                  <td>              
                    <img :src="row.photo" style="width:50px;" alt="">
                  </td>
                  
                  <td>{{ row.created_at }}</td>
                  <td>{{ row.updated_at }}</td>
                  <td> 
                    <vs-tooltip  style="display:inline">
                          <vs-button
                          style="display:inline"
                          icon
                          danger
                          @click="deleteRow(i,row)"
                        >
                          <i class='bx bxs-trash' ></i>
                        </vs-button>
                        <template #tooltip>
                            Effacer
                          </template>
                        </vs-tooltip>
                        <vs-tooltip  style="display:inline">
                          <vs-button
                          style="display:inline"
                          icon
                          success
                          @click="editRow(i,row)"
                        >
                          <i class='bx bxs-edit'></i>
                        </vs-button>
                          <template #tooltip>
                            Éditer
                          </template>
                        </vs-tooltip>
                  </td>

                </tr>
          </tbody>
        </table>
                
        <div v-if="!stades.length">Aucun stade trouvé</div>

        <template v-if="(per_page * current_page) < count ? true : false">
           <vs-button
              style="margin:auto"
              shadow
              @click="loadMore"
            >
              Charger plus
            </vs-button>
        </template>
        <!-- Add modal -->
          <vs-dialog v-if="row" blur v-model="addModal">
            <template #header>
              <h4 class="not-margin">
                Ajouter un <b>Stade</b>
              </h4>
            </template>
            
            <div class="con-form">
              <div class="row">
                <div class="col-sm-8">
                    <vs-input class="mb-3" block v-model="row.label" placeholder="Label">
                    </vs-input>

                    <select v-on:change="row.size = $event.target.value" class="mb-3 form-control">
                        <option value="-1">Terrain</option>
                        <option value="5">Foot 5</option>
                        <option value="6">Foot 6</option>
                        <option value="7">Foot 7</option>
                        <option value="8">Foot 8</option>
                        <option value="9">Foot 9</option>
                        <option value="10">Foot 10</option>
                        <option value="11">Foot 11</option>
                    </select>
                    <div>
                      <div class="row">
                        <div class="col-sm-8">
                          <vs-input class="mb-3" type="number" :min="0" :max="1000" block v-model="row.price" placeholder="Prix"></vs-input>
                        </div>
                        <div class="col-sm-4" style="text-align: left;padding-top: 1rem;">
                          <strong>Dhs</strong>
                        </div>
                      </div>
                    </div>

                </div>
                <div class="col-sm-4">
                    <img :src="tmpImg" style="width:100%;" alt="">
                </div>
              </div>
            </div>

            <template #footer>
              <div class="footer-dialog">
                <vs-button block success :disabled="!row || row.label != '' && row.price != '' && row.size != '-1' ? false : true  " @click="submitAdd()">
                  Ajouter
                </vs-button>
              </div>
            </template>
        </vs-dialog>
        <!-- Add modal -->

          <!-- edit modal -->
          <vs-dialog v-if="row" blur v-model="editModal">
            <template #header>
              <h4 class="not-margin">
                Éditer <b>{{row.label}}</b>
              </h4>
            </template>
            
            <div class="con-form">
              <div class="row">
                <div class="col-sm-8">
                    <vs-input class="mb-3" block v-model="row.label" placeholder="Étiquette">
                    </vs-input>

                    <select v-on:change="row.size = $event.target.value" class="mb-3 form-control">
                        <option value="-1">Terrain</option>
                        <option value="5">Foot 5</option>
                        <option value="6">Foot 6</option>
                        <option value="7">Foot 7</option>
                        <option value="8">Foot 8</option>
                        <option value="9">Foot 9</option>
                        <option value="10">Foot 10</option>
                        <option value="11">Foot 11</option>
                    </select>
                    <div>
                      <div class="row">
                        <div class="col-sm-8">
                          <vs-input class="mb-3" type="number" :min="0" :max="1000" block v-model="row.price" placeholder="Prix"></vs-input>
                        </div>
                        <div class="col-sm-4" style="text-align: left;padding-top: 1rem;">
                          <strong>Dhs</strong>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <img :src="tmpImg" style="width:100%;" alt="">
                </div>
              </div>
            </div>

            <template #footer>
              <div class="footer-dialog">
                <vs-button block success :disabled="!row || row.label != '' && row.price != '' && row.size != '-1' ? false : true  " @click="submitEdit(row)">
                  Éditer
                </vs-button>
              </div>
            </template>
        </vs-dialog>
        <!-- edit modal -->


        <!-- Delete modal -->
            <vs-dialog blur v-if="row" v-model="deleteModal" >
              <template #header>
                <h5 style="padding:1rem;">
                  Etes-vous sûr que vous voulez supprimer <b>{{row.label}}</b>
                </h5>
              </template>

              <template #footer>
                <div class="footer-dialog">
                  <vs-button danger block @click=submitDelete(row)>
                    Oui Supprimer
                  </vs-button>
                </div>
              </template>
            </vs-dialog>
        <!-- delete modal -->

        <!-- view modal -->
            <vs-dialog blur v-if="row" v-model="viewModal" >
              <template #header>
                <h4 class="not-margin">
                  <b>{{row.label}}</b>
                </h4>
              </template>
            
              <div class="con-form">
                <vs-input disabled class="mb-3" block v-model="row.label" placeholder="Étiquette">
                </vs-input>

                <vs-input disabled class="mb-3" block v-model="row.size" placeholder="Taille">
                </vs-input>

                <vs-input disabled class="mb-3" block v-model="row.price" placeholder="Prix">
                </vs-input>
              </div>
            </vs-dialog>
        <!-- view modal -->
      </div>  

    </div>
  </div>
</template>

<style>
  .con-form{
    max-width: 450px;
  }
</style>
