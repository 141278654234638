import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuesax from 'vuesax'
import * as boxicons from 'boxicons'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

import 'bootstrap'; import 'bootstrap/dist/css/bootstrap.min.css';
import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);


import 'bootstrap'; import 'bootstrap/dist/css/bootstrap.min.css';
import 'vuesax/dist/vuesax.css' //Vuesax styles

Vue.config.productionTip = false



Vue.use(Vuesax)
Vue.use(boxicons)

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
