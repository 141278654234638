<template>
   <vs-dialog v-model="state">
              <template #header>
                <h4 class="not-margin">
                  Reservations 
                </h4>
              </template>

              <div class="row">
                <vs-table>
                  <template #thead>
                    <vs-tr>
                      <vs-th>
                        Nom
                      </vs-th>
                      <vs-th>
                        Téléphone
                      </vs-th>
                      <vs-th>
                        Session
                      </vs-th>
                      <vs-th>
                        Image
                      </vs-th>
                      <vs-th>
                        Stade
                      </vs-th>
                      <vs-th>
                        Taille
                      </vs-th>
                      <vs-th>
                        Prix
                      </vs-th>
                    </vs-tr>
                  </template>
                  <template #tbody>
                    <vs-tr :key="i" v-for="(tr, i) in reservations" :data="tr">
                      <vs-td>
                        {{ tr.client.name }}
                      </vs-td>
                      <vs-td>
                      {{ tr.client.phone }}
                      </vs-td>
                      <vs-td style="width:180px">
                      <b>{{  tr.session }}</b>
                      </vs-td>
                      <vs-td>
                      <img :src="tr.stade.photo" width="50" />
                      </vs-td>
                      <vs-td>
                      {{ tr.stade.label }}
                      </vs-td>
                      <vs-td>
                      {{ tr.stade.size }}
                      </vs-td>
                      <vs-td>
                      {{ tr.stade.price }} Dh
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>

            </vs-dialog>
</template>

<script>

    import {
      axiosGet,
    } from '../../helper'

    import moment from 'moment'

export default {
    name : 'ReservationModal',
    props : ['client_id','session'],
    data(){
        return {
            reservations: [],
            state : true
        }
    },
    watch : {
        state(){
            this.$emit('reservationModalState',this.state)
        }
    },
    methods : {
        async getReservations(){
            this.reservations = []

            let query = `${process.env.VUE_APP_API_URL}/api/reservations`
            if(this.client_id){
                query = `${process.env.VUE_APP_API_URL}/api/reservations?client_id=${this.client_id}`
            }

            if(this.session){
                query = `${process.env.VUE_APP_API_URL}/api/reservations?session=${this.session}`
            }

            let response = await axiosGet(query)
            for(let i = 0 ; i < response.data.data.length ; i++){
                response.data.data[i].session = moment(response.data.data[i].session).format('YYYY-MM-DD HH:mm')
            }
            this.reservations = response.data.data

        }
    },
    mounted(){
        this.getReservations()
    }
}
</script>

<style>

</style>