<script>
  import LineChart from './components/LineChart'
  import Calendar  from './Calendar'
  import moment from 'moment'
  import {axiosGet} from '../helper'
  export default {
      components : {Calendar,LineChart},
      async created(){
          //clients
          let clients = await this.getCharts('clients')
          this.clients.labels = clients.label
          this.clients.datasets[0].data = clients.data
          this.clients.datasets[0].label = 'Clients' 
          this.clients.datasets[0].backgroundColor = '#007bff82' 
          this.clients.datasets[0].borderColor = '#007bff' 

          //reservations
          let reservations = await this.getCharts('reservations')
          this.reservations.labels = reservations.label
          this.reservations.datasets[0].data = reservations.data
          this.reservations.datasets[0].label = 'Reservations' 
          this.reservations.datasets[0].backgroundColor = 'rgb(23 201 100 / 40%)' 
          this.reservations.datasets[0].borderColor = 'rgb(23, 201, 100)' 

          //reservations
          let revenue = await this.getCharts('revenue')
          this.revenue.labels = revenue.label
          this.revenue.datasets[0].data = revenue.data
          this.revenue.datasets[0].label = 'Revenue' 
          this.revenue.datasets[0].backgroundColor = 'rgb(255 130 0 / 51%)' 
          this.revenue.datasets[0].borderColor = 'rgb(255, 130, 0)' 

          window.line1.update();
          window.line2.update();
          window.line3.update();
      },
      data:() => ({
          active: 'dashboard',
          clients : {
                  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                  datasets: [{
                      label: 'My First dataset',
                      backgroundColor: '#5b3cc4',
                      borderColor: '#5b3cc4',
                      data: []
                  }]
          },
          reservations : {
                  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                  datasets: [{
                      label: 'My First dataset',
                      backgroundColor: 'rgb(23, 201, 100)',
                      borderColor: 'rgb(23, 201, 100)',
                      data: []
                  }]
          },
          revenue : {
                  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                  datasets: [{
                      label: 'My First dataset',
                      backgroundColor: 'rgb(23, 201, 100)',
                      borderColor: 'rgb(23, 201, 100)',
                      data: []
                  }]
          },
          value : '',
          range : 'last_3d'
      }),
      methods : {
          async getCharts(type){
            let start = null,
                end   = null;
            if(this.range == 'last_3d'){
              start  = moment().format('YYYY-MM-DD'),
                end   = moment().subtract('3','days').format('YYYY-MM-DD')
            }else if (this.range == 'last_7d'){
              start = moment().format('YYYY-MM-DD'),
              end   = moment().subtract('7','days').format('YYYY-MM-DD')
            }else if (this.range == 'last_30d'){
              start = moment().format('YYYY-MM-DD'),
              end   = moment().subtract('30','days').format('YYYY-MM-DD')
            }else{
              start = moment().format('YYYY-MM-DD'),
              end   = moment().subtract('3','days').format('YYYY-MM-DD')
            }
            console.log('start',start)
            console.log("end",end)


            let response = await axiosGet(`${process.env.VUE_APP_API_URL}/api/businesses/analytics?start=${end}&end=${start}&type=${type}`)
            return response.data
          },
          async getChartsData(){
            //clients
            let clients = await this.getCharts('clients')
            this.clients.labels = clients.label
            this.clients.datasets[0].data = clients.data
            this.clients.datasets[0].label = 'Clients' 
            this.clients.datasets[0].backgroundColor = '#007bff82' 
            this.clients.datasets[0].borderColor = '#007bff' 

            //reservations
            let reservations = await this.getCharts('reservations')
            this.reservations.labels = reservations.label
            this.reservations.datasets[0].data = reservations.data
            this.reservations.datasets[0].label = 'Reservations' 
            this.reservations.datasets[0].backgroundColor = 'rgb(23 201 100 / 40%)' 
            this.reservations.datasets[0].borderColor = 'rgb(23, 201, 100)' 

            //reservations
            let revenue = await this.getCharts('revenue')
            this.revenue.labels = revenue.label
            this.revenue.datasets[0].data = revenue.data
            this.revenue.datasets[0].label = 'Revenue' 
            this.revenue.datasets[0].backgroundColor = 'rgb(255 130 0 / 51%)' 
            this.revenue.datasets[0].borderColor = 'rgb(255, 130, 0)' 

            window.line1.update();
            window.line2.update();
            window.line3.update();
          }
      },
      watch : {
          range : {
            handler(){
              this.getChartsData()
            },deep : true
          }
      }
  }
</script>
<template>
    <div>
      <div class="row">
        <div class="col-lg-9 col-sm-12">
          <div class="card" style="margin-bottom:1rem">
            <Calendar :dashboard="'true'" />
          </div>
        </div>
        <div class="dashboard-stats col-lg-3 col-sm-12">
           <div class="row">

             <div class="col-sm-12" style="margin-bottom:1rem">
                <div class="card">
                  <select style="height: 35px;border: 2px solid #21212100;" v-model="range" @change="range = $event.target.value">
                    <option :value="'last_3d'">3 derniers jours</option>
                    <option :value="'last_7d'">7 derniers jours</option>
                    <option :value="'last_30d'">30 derniers jours</option>
                  </select>
                </div>
             </div>

              <div class="col-sm-12" style="margin-bottom:1rem">
                <LineChart chartId="line1" :data="clients" />
              </div>
              <div class="col-sm-12" style="margin-bottom:1rem">
                <LineChart chartId="line2" :data="reservations"/>
              </div>
              <div class="col-sm-12" style="margin-bottom:1rem">
                <LineChart chartId="line3" :data="revenue"/>
              </div>
           </div>
        </div> 
      </div>
  </div>
</template>

<style>
@media (max-width: 990px) {
  .custom-container{
    margin: 0rem 0rem;
  }
  .fc-header-toolbar{
    display: none !important;
  }
  /* .dashboard-stats{
    display: none;
  } */
}
</style>