
import axios from 'axios'

const axiosGet = async (url) =>{
    try{
        console.log(localStorage.getItem('access_token'))
        const response = await axios.get(url,{
        headers : {
            Authorization : localStorage.getItem('access_token')
        }})
        return response;
    }catch(error){
        console.log("error",error)
        if(error.response.status == 401){
            localStorage.removeItem('access_token')
            window.location = "/login"
        }
    }
}

const axiosPost = async (url,body) =>{
    try{
    return axios.post(
        url,
        body,
        {
            headers : {
                Authorization : localStorage.getItem('access_token')
            }
        })

    }catch(error){
        if(error.response.status == 401){
            localStorage.removeItem('access_token')
            window.location = "/login"
        }
    }
}

const axiosPatch = async (url,body) =>{
    try{
    return axios.patch(
        url,
        body,
        {
            headers : {
                Authorization : localStorage.getItem('access_token')
            }
        })

    }catch(error){
        if(error.response.status == 401){
            localStorage.removeItem('access_token')
            window.location = "/login"
        }
    }
}

const axiosDelete = async (url) =>{
    try{
        const response = await axios.delete(url,{
        headers : {
            Authorization : localStorage.getItem('access_token')
        }})
        return response;
    }catch(error){
        if(error.response.status == 401){
            localStorage.removeItem('access_token')
            window.location = "/login"
        }
    }
}

export {axiosGet,axiosPost,axiosPatch,axiosDelete}